import * as React from 'react'
import Header from './header'
import Footer from './footer'

export default function LayoutHeaderAndFooter({ children }: { children: any }) {
  return (
    <React.Fragment>
      <Header />
      {children}
      <Footer />
    </React.Fragment>
  )
}