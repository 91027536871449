import * as React from 'react';
import { icon } from '../../styles/class-names';

export default function IconWikimedia() {
  return (<span className={icon}>
  <svg viewBox="-599 -599 1198 1198" width="1024" height="1024">
  <defs>
      <clipPath id="mask">
          <path d="M 47.5,-87.5 v 425 h -95 v -425 l -552,-552 v 1250 h 1199 v -1250 z" />
      </clipPath>
  </defs>
  <g clipPath="url(#mask)">
      <circle id="green parts" fill="#396" r="336.5"/>
      <circle id="blue arc" fill="none" stroke="#069" r="480.25" strokeWidth="135.5" />
  </g>
  <circle fill="#900" cy="-379.5" r="184.5" id="red circle"/>
</svg>
</span>);
}