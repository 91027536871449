import { useStaticQuery, Link, graphql } from 'gatsby';
import * as React from 'react';
import { spaceBetween, displayFlex, borderBottom, padding10 } from '../styles/class-names';

export default function Header() {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);
	return (
		<header className={`${displayFlex} ${spaceBetween} ${borderBottom} ${padding10}`}>
			<h2><Link to="/" title="Takes you back to michaeljbennett.info's main landing page">{data.site.siteMetadata.title}</Link></h2>
			<nav>
				<Link to="/about" title="Information about Michael J. Bennett">about</Link>
				&nbsp;&nbsp;&nbsp;
				<Link to="/photos" title="Michael J. Bennett's amateur photography work">photos</Link>
			</nav>
		</header>
	);
}
