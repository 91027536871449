import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import {
	displayFlex,
	flexColumn,
	flexGrow1,
	displayBlock,
	spaceBetween,
	flexBasis0,
	padding10,
	noWrap,
	alignSelfEnd,
	alignSelfCentre,
	borderTop,
} from '../styles/class-names';
import IconGithub from './icons/icon-github';
import IconWikimedia from './icons/icon-wikimedia';
import IconTwitter from './icons/icon-twitter';

export default function Footer() {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						disclaimer
						email
						github
						title
						twitter
						wikimedia
					}
				}
			}
		`
	).site.siteMetadata;

	const col = `${displayFlex} ${flexColumn} ${flexBasis0} ${padding10}`;

	return (
		<footer
			className={`${displayFlex} ${spaceBetween} ${borderTop} ${padding10}`}
		>
			<div className={col} style={{ paddingLeft: 0 }}>
				<h4
					className={noWrap}
					style={{ paddingBottom: '10px', paddingRight: '10px' }}
				>
					My Foot
				</h4>
				<span className={displayBlock}>{data.title}</span>
				<a
					className={displayBlock}
					href={`mailto:${data.email}`}
					title={`opens your default email application and starts an email to ${data.email}`}
				>
					email
				</a>
			</div>
			<div className={`${col} ${alignSelfCentre}`}>
				<a
					rel="noopener"
					className={noWrap}
					href={data.github}
					target="_blank"
					title={`lots of weird and mostly useless source code written by Michael J. Bennett`}
				>
					<IconGithub />
					&nbsp; github
				</a>
				<a
					rel="noopener"
					className={noWrap}
					href={data.twitter}
					target="_blank"
					title={`twitter, it might have its challenges but it's less sketchy than its bigger competitors`}
				>
          <IconTwitter />&nbsp;
					twitter
				</a>
				<a
					rel="noopener"
					className={noWrap}
					href={data.wikimedia}
					target="_blank"
					title={`some of Michael J. Bennett's copyleft media`}
				>
          <IconWikimedia />&nbsp;
					wikimedia
				</a>
			</div>
			<div className={`${col} ${flexGrow1} ${alignSelfEnd}`}>
				{data.disclaimer}
			</div>
		</footer>
	);
}
