export const alignSelfCentre = 'align-self-centre';
export const alignSelfEnd = 'align-self-end';
export const borderBottom = 'border-bottom';
export const borderTop = 'border-top';
export const displayBlock = 'display-block';
export const displayFlex = 'display-flex';
export const flexBasis0 = 'flex-basis-0';
export const flexColumn = 'flex-column';
export const flexGrow1 = 'flex-grow-1';
export const fullWidth = 'full-width';
export const icon = 'icon';
export const noBullet = 'no-bullet';
export const noWrap = 'no-wrap';
export const padding10 = 'padding-10';
export const spaceBetween = 'space-between';
